const Start = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
    >
      <g filter="url(#filter0_b_829_3839)">
        <rect
          x="1"
          y="1"
          width="64"
          height="64"
          rx="32"
          fill="black"
          fillOpacity="0.5"
        />
        <rect
          x="1"
          y="1"
          width="64"
          height="64"
          rx="32"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="1.5"
        />
        <path
          d="M26 42.3587V23.8028C26 22.6047 27.3352 21.8901 28.3321 22.5547L43.0221 32.3481C43.9398 32.9598 43.9057 34.3194 42.9585 34.8844L28.2684 43.6469C27.2686 44.2433 26 43.5229 26 42.3587Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_829_3839"
          x="-3.75"
          y="-3.75"
          width="73.5"
          height="73.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_829_3839"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_829_3839"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
export default Start
