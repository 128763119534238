import React from 'react'
import { TypographyProps } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import theme from '@evocolabs/orkaui/lib/theme.cjs'

const ContentTitle = (props: TypographyProps): JSX.Element => {
  return (
    <Typography
      variant="T2"
      textAlign="center"
      color={theme.palette.primary[700]}
      sx={{
        fontSize: {
          xs: '20px',
          dl: '24px'
        }
      }}
      {...props}
    />
  )
}

export default ContentTitle
