import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import Image, { ImageProps } from 'next/image'
import { ContentTitle } from './title'
import Start from './Player/Start'

export interface CardInfo {
  src: string
  title: string
  content?: string
}

export interface ImageCardProps
  extends CardInfo,
    Omit<StackProps, 'title' | 'content'> {
  aspectRatio?: number
  horizontal?: boolean
  imageProps?: Partial<ImageProps>
  variant?: 'image' | 'video'
  imageWrapperProps?: Omit<BoxProps, 'position' | 'flex' | 'paddingBottom'>
  onPlay?: () => void
}

const StartIcon = ({ onClick }: { onClick: () => void }) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        borderRadius: '50%',
        overflow: 'hidden'
      }}
    >
      <Start />
    </Box>
  </Box>
)

export function ImageCard({
  children,
  title,
  content,
  src,
  horizontal = false,
  aspectRatio = 2 / 3,
  imageProps,
  variant = 'image',
  imageWrapperProps,
  onPlay,
  ...restProps
}: ImageCardProps) {
  return (
    <Stack
      flex="1 1 0"
      direction={horizontal ? 'row' : 'column'}
      alignItems={horizontal ? 'center' : 'stretch'}
      spacing={3}
      {...restProps}
    >
      <Box
        paddingBottom={
          horizontal ? `${(1 - aspectRatio) * 100}%` : `${aspectRatio * 100}%`
        }
        position="relative"
        flex={horizontal ? '1 1 0' : '0 1 auto'}
        {...imageWrapperProps}
      >
        <Image
          src={src}
          fill
          alt={title}
          style={{ borderRadius: '12px', objectFit: 'cover' }}
          unoptimized
          {...imageProps}
        />
        {variant === 'video' ? <StartIcon onClick={onPlay} /> : null}
      </Box>
      {children || (
        <Box flex={horizontal ? '1 1 0' : '0 1 auto'}>
          <ContentTitle textAlign="left" mt={3}>
            {title}
          </ContentTitle>
          <Typography mt={1} variant="body2">
            {content}
          </Typography>
        </Box>
      )}
    </Stack>
  )
}

export default ImageCard
