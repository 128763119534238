const MenuData = [
  { label: 'ORKA', link: '/', icon: '' },
  [
    {
      label: 'Product',
      link: '/',
      icon: '',
      children: [
        { label: 'Explore Orka Two', link: '/OrkaTwo', variant: 'link' },
        { label: 'OrkaCare', link: '/OrkaCare', variant: 'link' },
        { label: 'Reviews', link: '/Reviews', variant: 'link' }
      ]
    }
  ],
  [
    {
      label: 'Support',
      link: '/',
      icon: '',
      children: [
        { label: 'Support Center', link: '/Support', variant: 'link' },
        { label: 'How-to Video', link: '/HowToVideo', variant: 'link' },
        { label: 'Troubleshooting', link: '/TroubleShooting', variant: 'link' }
      ]
    }
  ],
  [
    {
      label: 'Education',
      link: '/',
      icon: '',
      children: [
        { label: 'Hearing Blog', link: '/HearingBlog', variant: 'link' },
      ]
    }
  ],
  [
    {
      label: 'Company',
      link: '/',
      icon: '',
      children: [
        { label: 'Who We Are', link: '/AboutUs', variant: 'link' },
        { label: 'Why We Care', link: '/world-hearing-day', variant: 'link' },
        { label: 'Press Kit', link: '/PressKit', variant: 'link' }
      ]
    }
  ]
]

export { MenuData }
