import { Box } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { MenuData } from './utils'
import React from 'react'
import { Typography } from '@evocolabs/orkaui'
import { showLeaveQuestionsModal } from '@/src/components/LeaveQuestions/LeaveQuestionsSlices'
import theme from '@/src/theme'
import { useDispatch } from 'react-redux'

const poliyData = {
  warrantyAndRefundPolicy: 'Warranty and Return Policy',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  copyright: `Copyright © ${new Date().getFullYear()} Orka Labs Inc. All rights reserved.`
}

const Child = function ({ state, children }) {
  const dispatch = useDispatch()
  const childrenMap = {
    link: (
      <a style={{ display: 'flex' }} href={state.link}>
        {/* <Link style={{ display: 'flex' }} href={state.link}> */}
        {children}
        {/* </Link> */}
      </a>
    ),
    default: (
      <Box display="flex" onClick={state.onClick}>
        {children}
      </Box>
    ),
    reduxAction: (
      <Box
        display="flex"
        onClick={() => {
          dispatch(showLeaveQuestionsModal())
        }}
      >
        {children}
      </Box>
    )
  }
  return childrenMap[state.variant]
}

const MenuItem = function ({ data, sx }) {
  return (
    <Box
      sx={sx}
      display="flex"
      flexDirection={'column'}
      flex={{ xs: '100%', sm: '50%', dl: 1 }}
    >
      <Typography variant="Body2">{data.label}</Typography>
      {data?.children?.map((c) => (
        <Box mt="16px" display="flex" alignItems="center" key={c.label}>
          <Child state={c}>
            {c.icon && (
              <c.icon
                size={32}
                sx={{
                  cursor: 'pointer'
                }}
              />
            )}
            <Typography
              id={`footer_${data.label.toLowerCase()}_${c.label
                .toLowerCase()
                .replace(/\s/g, '_')}`}
              variant="body2"
              sx={{
                '* + &': { marginLeft: '12px' },
                '&:hover': {
                  textDecoration: 'solid underline #506A8A 2px',
                  textDecorationSkipInk: 'none',
                  textUnderlinePosition: 'under'
                },
                cursor: 'pointer'
              }}
            >
              {c.label}
            </Typography>
          </Child>
        </Box>
      ))}
    </Box>
  )
}
const Menu = function (props) {

  return (
    <Box
      sx={{
        display: props.display,
        flexDirection: { xs: 'column', dl: 'row' },
        gap: { xs: '32px', dl: '128px' }
      }}
    >
      <Box id="footer_logo_and_orka">
        <Link href={MenuData[0].link}>
          <Image
            src="/imgs/orkaTwo/logoAndOrka.svg"
            width="154"
            height="40"
            alt="Orka Logo"
          />
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        width="100%"
        mb="32px"
        flexWrap={{ xs: 'wrap', dl: 'nowrap' }}
        sx={{
          '>*:nth-of-type(1),>*:nth-of-type(3)': {
            order: { xs: -1, dl: 0 },
            mb: { xs: '24px', sm: '40px', dl: 0 }
          },
          '>*': { mb: { xs: '24px', sm: 0 } }
        }}
        {...props}
      >
        {MenuData.slice(1).map((m) =>
          m.length < 2 ? (
            <MenuItem key={m[0].label} data={m[0]} />
          ) : (
            <Box flex={{ xs: '100%', sm: '50%', dl: 1 }} key={m[0].label}>
              {m.map((menu) => (
                <MenuItem
                  sx={{ '& + &': { mt: { xs: '24px' } } }}
                  key={menu.label}
                  data={menu}
                />
              ))}
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}
const Line = function (props) {
  return (
    <Box
      width="100%"
      height={{ xs: '1px', dl: '2px' }}
      bgcolor="#B8B6B5"
      {...props}
    />
  )
}

const HoverIcon = function ({
  icon,
  hoverIcon,
  fillColor = '#fff',
  hoverColor = '#415670',
  link,
  id
}) {
  return (
    <a href={link} target="__blank" aria-label={link}>
      <Box
        id={id}
        width="48px"
        height="48px"
        display="flex"
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        border={'solid 24px ' + fillColor}
        borderRadius="24px"
        sx={{
          '&:hover': { border: 'solid 24px ' + hoverColor },
          '& > :first-of-type': { display: 'block!important' },
          '& > :last-child': { display: 'none!important' },
          '&:hover > :first-of-type': { display: 'none!important' },
          '&:hover > :last-child': { display: 'block!important' }
        }}
      >
        {icon}
        {hoverIcon}
      </Box>
      {/* </Link> */}
    </a>
  )
}

const policy = (
  <Typography variant="body3">
    I agree to Orka's{' '}
    <Link href={'/legal/PrivacyPolicy'} target="__blank">
      <Typography variant="Link">Privacy Policy</Typography>
    </Link>{' '}
    and{' '}
    <Link href={'/legal/TermsOfService'} target="__blank">
      <Typography variant="Link">Terms of Service</Typography>
    </Link>
    .
  </Typography>
)

const WarrantyandReturnPolicy = function () {
  return (
    <Typography flexShrink="0" variant="Body5" mr="16px" color="#756F6E">
      <Link href="/legal/WarrantyAndReturnPolicy">
        {poliyData.warrantyAndRefundPolicy}
      </Link>
    </Typography>
  )
}
const TermsOfService = function () {
  return (
    <Typography flexShrink="0" variant="Body5" mr="16px" color="#756F6E">
      <Link href="/legal/TermsOfService">{poliyData.termsOfService}</Link>
    </Typography>
  )
}
const PrivacyPolicy = function () {
  return (
    <Typography
      flexShrink="0"
      variant="Body5"
      mr={{ xs: 0, md: '16px' }}
      color="#756F6E"
    >
      <Link href="/legal/PrivacyPolicy">{poliyData.privacyPolicy}</Link>
    </Typography>
  )
}
const CopyRight = function () {
  return (
    <Typography
      flexShrink="0"
      sx={{ textAlign: 'center' }}
      variant="body5"
      color="#756F6E"
    >
      {poliyData.copyright}
    </Typography>
  )
}

const Footer2 = function ({
  miniMode = false,
  bgcolor = 'transparent',
  sx = {}
} = props) {
  return (
    <Box
      sx={{
        backgroundColor: miniMode ? bgcolor : theme.palette.secondary[100],
        width: '100%'
      }}
    >
      <Box
        sx={{
          maxWidth: '1440px',
          margin: 'auto',
          padding: {
            xs: '64px 16px 24px',
            sm: '64px 80px 24px',
            dl: '64px 108px 24px'
          },
          ...sx
        }}
      >
        <Menu display={miniMode ? 'none' : 'flex'} />
        <Box
          padding={{ xs: '0 12%', dl: '0' }}
          display="flex"
          justifyContent="center"
          mt="8px"
          flexWrap={{ xs: 'wrap', dl: 'nowrap' }}
        >
          <Box
            display="flex"
            justifyContent="center"
            mr="8px"
            flexWrap={{ xs: 'wrap', dl: 'nowrap' }}
          >
            <WarrantyandReturnPolicy />
            <TermsOfService />
            <PrivacyPolicy />
          </Box>
          <CopyRight />
        </Box>
      </Box>
    </Box>
  )
}

export default Footer2
