import React from 'react'
import { Typography } from '@evocolabs/orkaui'
import theme from '@evocolabs/orkaui/lib/theme.cjs'
import { TypographyProps } from '@mui/material'

function SectionTitle({
  children,
  ...restProps
}: TypographyProps): JSX.Element {
  return (
    <Typography
      variant="h2"
      textAlign="center"
      color={theme.palette.primary[700]}
      sx={{
        fontSize: {
          xs: '24px',
          dl: '40px'
        },
        lineHeight: {
          xs: '32px',
          dl: '42px'
        }
      }}
      {...restProps}
    >
      {children}
    </Typography>
  )
}
export default SectionTitle
